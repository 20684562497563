import React, { Dispatch, FormEventHandler, SetStateAction, useEffect, useState, VFC } from "react"
import { FormState, useFormState } from "react-use-form-state"
import Confetti from 'react-dom-confetti'
import * as z from "zod"

import './Form.css'

const formSchema = z.object({
  firstName: z.string(),
  email: z.string().email(),
  favouriteNumber: z.number(),
  favouriteColour: z.enum(["blue", "not blue"]),
})
type TForm = z.infer<typeof formSchema>
type TErrors = Record<string, string[]>

function useConfetti(): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    if (showConfetti) {
      setShowConfetti(false)
    }
  }, [setShowConfetti, showConfetti])

  return [showConfetti, setShowConfetti]
}

function useFormErrors() {
  return (formState: FormState<TForm>) => (errors: TErrors): void => {
    const invalidFields = Object.keys(errors) as Array<keyof TForm>
    invalidFields.forEach(field =>
      formState.setFieldError(field, errors[field].join("; "))
    )

    const validFields = (Object.keys(formState.values) as Array<keyof TForm>)
      .filter(field => !invalidFields.includes(field))
    validFields.forEach(field =>
      formState.setFieldError(field, null)
    )
  }
}

function useFormSubmit({ formState, onSuccess }: { formState: FormState<TForm>, onSuccess: () => void }): FormEventHandler {
  const handleErrors = useFormErrors()

  return event => {
    event.preventDefault()
    try {
      formSchema.parse({
        ...formState.values,
        favouriteNumber: parseInt(formState.values.favouriteNumber),
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        handleErrors(formState)(error.errors.reduce((errors, error) => ({
          ...errors,
          [error.path[0]]: [error.message]
        }), {} as TErrors))
        return
      }
    }
    onSuccess()
  }
}

const validateField = (field: keyof TForm) =>
  (value: unknown): string => {
    const parsedResult = formSchema
      .pick({ [field]: true })
      .safeParse({ [field]: value })
    return !parsedResult.success
      ? parsedResult.error.errors[0].message
      : ""
  }

const Form: VFC = () => {
  const [formState, { number, text }] = useFormState<TForm>()
  const [showConfetti, setShowConfetti] = useConfetti()
  const handleErrors = useFormErrors()
  const handleSubmit = useFormSubmit({
    formState,
    onSuccess() {
      handleErrors(formState)
      setShowConfetti(true)
      formState.reset()
    }
  })

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div>
        <label>
          First name
          <input
            {...text({
              name: "firstName",
              validate: validateField("firstName"),
            })}
          />
        </label>
        <p>{formState.errors.firstName}</p>
      </div>
      <div>
        <label>
          Email
          <input
            data-has-error={!!formState.errors.email}
            {...text({
              name: "email",
              validate: validateField("email"),
            })}
          />
        </label>
        <p>{formState.errors.email}</p>
      </div>
      <div>
        <label>
          Favourite number
          <input
            data-has-error={!!formState.errors.favouriteNumber}
            {...number({
              name: "favouriteNumber",
              validate: value => {
                return validateField("favouriteNumber")(parseInt(value))
              },
            })}
          />
        </label>
        <p>{formState.errors.favouriteNumber}</p>
      </div>
      <div>
        <label>
          Favourite colour
          <input
            data-has-error={!!formState.errors.favouriteColour}
            {...text({
              name: "favouriteColour",
              validate: validateField("favouriteColour"),
            })}
          />
        </label>
        <p>{formState.errors.favouriteColour}</p>
      </div>
      <div>
        <button type="submit">
          Submit
          <Confetti active={showConfetti} />
        </button>
      </div>
    </form>
  )
}

export default Form
